<template>
  <div class="vx-row">
    <div class="vx-col md:w-3/4">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        :filename="`${code_transfert}`"
        :pdf-quality="1"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"

        @progress="onProgress($event)"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
          <!-- INVOICE METADATA -->
          <div class="vx-row leading-loose p-base">
            <div class="vx-col w-1/2 mt-base">
              <img :src="getLogoUrl" alt="groupe-logo" height="100px">
            </div>
            <div class="vx-col w-1/2 text-right">
              <h3>Transfert article</h3>
              <div class="invoice__invoice-detail mt-6">
                <h6>N°.</h6>
                <p>{{ code_transfert }}</p>

                <h6 class="mt-4">DATE</h6>
                <p>{{ date_transfert | moment("calendar", "July 10 2011") }}</p>
              </div>
            </div>
            <div class="vx-col w-1/2 mt-12">
              <h5>Origine:</h5>
              <div class="invoice__recipient-info my-4">
                <p>{{ getOfficineName(Officine_origine) }}</p>
              </div>
            </div>
            <div class="vx-col w-1/2 mt-base text-right mt-12">
              <h5>Destination:</h5>
              <div class="invoice__company-info my-4">
                <p>{{ getOfficineName(Officine_destination) }}</p>
              </div>
            </div>
          </div>

          <!-- INVOICE CONTENT -->
          <div v-if="code_transfert != ''" class="p-base">
            <vs-table hoverFlat :data="details">
              <!-- HEADER -->
              <template slot="thead">
                <vs-th class="pointer-events-none">Etiquette</vs-th>
                <vs-th class="pointer-events-none">Marque</vs-th>
                <vs-th class="pointer-events-none">Modèle</vs-th>
                <vs-th class="pointer-events-none">Couleur</vs-th>
                <vs-th class="pointer-events-none">Taille</vs-th>
                <vs-th class="pointer-events-none">Type</vs-th>
                <vs-th class="pointer-events-none">Qte</vs-th>
                <vs-th class="pointer-events-none">PR</vs-th>
                <vs-th class="pointer-events-none">PVTTC</vs-th>
              </template>
              <!-- DATA -->
              <template  class="mb-5">
                <vs-tr v-for="(item, index) in details" :key="index">
                  <vs-td data-label="Etiquette">
                    {{item.Etiquette}}
                  </vs-td>
                  <vs-td data-label="Marque">
                    {{getMarqueName(item.IdMarque)}}
                  </vs-td>
                  <vs-td data-label="Modele">
                    {{item.Modele}}
                  </vs-td>
                  <vs-td data-label="Couleur">
                    {{item.Couleur}}
                  </vs-td>
                  <vs-td data-label="Taille">
                    {{item.Taille[0].Longeur}}/{{item.Taille[0].Nez}}/{{item.Taille[0].Hauteur}}
                  </vs-td>
                  <vs-td data-label="Type">
                    {{ getTypeName(item.IdTypeArt)}}
                  </vs-td>
                  <vs-td data-label="QteAchat">
                    {{item.stock}}
                  </vs-td>
                  <vs-td data-label="PrixAchatBrut">
                    {{item.PrixAchatBrut}}
                  </vs-td>
                  <vs-td data-label="PrixAchatBrut">
                    {{item.PrixVenteTTC}}
                  </vs-td>

                </vs-tr>
              </template>
            </vs-table>
            <vs-table hoverFlat class="w-1/2 ml-auto mt-4">
              <vs-tr>
                <vs-th class="pointer-events-none">QTITE TOTAL</vs-th>
                <vs-td> {{QteTotal}} </vs-td>
              </vs-tr>
              <vs-tr>
                <vs-th class="pointer-events-none">TOTAL PA</vs-th>
                <vs-td> {{TotalPa}} FCFA</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-th class="pointer-events-none">TOTAL PVTCC</vs-th>
                <vs-td> {{TotalPv}} FCFA</vs-td>
              </vs-tr>
            </vs-table>
            <div class="mt-5">
              Arrêté la présente facture à la somme de: {{NumberToLetter(TotalPv)}}
            </div>
          <vs-divider/>
          <!-- INVOICE FOOTER -->
          <div class="invoice__footer text-center p-1">
            <!-- <p class="mb-4">
              FOVEA Group SAS au capital de 1 000 000 FCFA-RC: CI-ABJ-2015-B-20954 -CC: 15 42870 E-Régime
                d'imposition: Réel Simplifié -
              </p>
            <p>
              <span>DGI: Marcory 1- 21 BP 5379 Abidjan 21 - Tel: 22 26 20 93 - 58 55 55 19</span><br>
              <span><span class="font-semibold"> www.anoptic.com</span></span>
            </p> -->
          </div>
          </div>
        </section>
      </vue-html2pdf>
      <vx-card>
        <!-- INVOICE METADATA -->
        <div class="vx-row leading-loose p-base">
          <div class="vx-col w-1/2 mt-base">
            <img :src="getLogoUrl" alt="groupe-logo" height="100px">
          </div>
          <div class="vx-col w-1/2 text-right">
            <h3>Transfert article</h3>
            <div class="invoice__invoice-detail mt-6">
              <h6>N°.</h6>
              <p>{{ code_transfert }}</p>

              <h6 class="mt-4">DATE</h6>
              <p>{{ date_transfert | moment("calendar", "July 10 2011") }}</p>
            </div>
          </div>
          <div class="vx-col w-1/2 mt-12">
            <h5>Origine:</h5>
            <div class="invoice__recipient-info my-4">
              <p>{{ getOfficineName(Officine_origine) }}</p>
            </div>
          </div>
          <div class="vx-col w-1/2 mt-base text-right mt-12">
            <h5>destination:</h5>
            <div class="invoice__company-info my-4">
              <p>{{ getOfficineName(Officine_destination) }}</p>
            </div>
          </div>
        </div>

        <!-- INVOICE CONTENT -->
        <div v-if="code_transfert != ''" class="p-base">
          <vs-table hoverFlat :data="details">
            <!-- HEADER -->
            <template slot="thead">
              <vs-th class="pointer-events-none">Etiquette</vs-th>
              <vs-th class="pointer-events-none">Marque</vs-th>
              <vs-th class="pointer-events-none">Modèle</vs-th>
              <vs-th class="pointer-events-none">Couleur</vs-th>
              <vs-th class="pointer-events-none">Taille</vs-th>
              <vs-th class="pointer-events-none">Type</vs-th>
              <vs-th class="pointer-events-none">Qte</vs-th>
              <vs-th class="pointer-events-none">PR</vs-th>
              <vs-th class="pointer-events-none">PVTTC</vs-th>
            </template>
            <!-- DATA -->
            <template  class="mb-5">
              <vs-tr v-for="(item, index) in details" :key="index">
                <vs-td data-label="Etiquette">
                  {{item.Etiquette}}
                </vs-td>
                <vs-td data-label="Marque">
                  {{getMarqueName(item.IdMarque)}}
                </vs-td>
                <vs-td data-label="Modele">
                  {{item.Modele}}
                </vs-td>
                <vs-td data-label="Couleur">
                  {{item.Couleur}}
                </vs-td>
                <vs-td data-label="Taille">
                  {{item.Taille[0].Longeur}}/{{item.Taille[0].Nez}}/{{item.Taille[0].Hauteur}}
                </vs-td>
                <vs-td data-label="Type">
                  {{ getTypeName(item.IdTypeArt)}}
                </vs-td>
                <vs-td data-label="QteAchat">
                  {{item.stock}}
                </vs-td>
                <vs-td data-label="PrixAchatBrut">
                  {{item.PrixAchatBrut}}
                </vs-td>
                <vs-td data-label="PrixAchatBrut">
                  {{item.PrixVenteTTC}}
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>
          <vs-table hoverFlat class="w-1/2 ml-auto mt-4">
            <vs-tr>
              <vs-th class="pointer-events-none">QTITE TOTAL</vs-th>
              <vs-td> {{QteTotal}} </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-th class="pointer-events-none">TOTAL PR</vs-th>
              <vs-td> {{TotalPa}} FCFA</vs-td>
            </vs-tr>
            <vs-tr>
              <vs-th class="pointer-events-none">TOTAL PV TTC</vs-th>
              <vs-td> {{TotalPv}} FCFA</vs-td>
            </vs-tr>
          </vs-table>
          <div class="mt-5">
            Arrêté la présente facture à la somme de: {{NumberToLetter(TotalPv)}}
          </div>
        <vs-divider/>
        <!-- INVOICE FOOTER -->
        <div class="invoice__footer text-center p-1">
          <!-- <p class="mb-4">
            FOVEA Group SAS au capital de 1 000 000 FCFA-RC: CI-ABJ-2015-B-20954 -CC: 15 42870 E-Régime
              d'imposition: Réel Simplifié -
            </p>
          <p>
            <span>DGI: Marcory 1- 21 BP 5379 Abidjan 21 - Tel: 22 26 20 93 - 58 55 55 19</span><br>
            <span><span class="font-semibold"> www.anoptic.com</span></span>
          </p> -->
        </div>
        </div>

      </vx-card>
    </div>
    <div class="vx-col md:w-1/4">
      <div class="mt-4"><vs-button class="w-full" color="primary" type="filled" @click="PrintCommandeFourn">Imprimer</vs-button></div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  name: 'DetailFacture',
  components: {
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      code_transfert: '',
      date_transfert: '',
      Officine_origine: '',
      Officine_destination: '',
      observation: '',
      details: [],
      activeUserInfos: null,
      idOfficine: '',
      montantEnLettre: ''
    }
  },
  computed: {
    getArticleTransfererById () {
      return this.$store.getters['stock/getArticleTransfererById'](this.$route.params.articleTransfereId)
    },

    QteTotal () {
      const total = this.details.reduce(function (accumulator, article) {
        return accumulator + (article.stock * 1)
      }, 0)
      return total
    },
    TotalPa () {
      const total = this.details.reduce(function (accumulator, article) {
        return accumulator + (article.PrixAchatBrut *  article.stock)
      }, 0)
      /* Formatage du calcul */
      const val = (total / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    TotalPv () {
      const total = this.details.reduce(function (accumulator, article) {
        return accumulator + (article.PrixVenteTTC * article.stock)
      }, 0)
      /* Formatage du calcul */
      const val = (total / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getLogoUrl () {
      return this.$store.getters['entete_piedpage/getLogoUrl']
    },
    getPiedpageUrl () {
      return this.$store.getters['entete_piedpage/getPiedpageUrl']
    }

  },
  methods: {
    /* conversion nombre en lettre */
    Unite (nombre) {
      let unite = ''
      switch (nombre) {
      case 0:
        unite = 'zéro'
        break
      case 1:
        unite = 'un'
        break
      case 2:
        unite = 'deux'
        break
      case 3:
        unite = 'trois'
        break
      case 4:
        unite = 'quatre'
        break
      case 5:
        unite = 'cinq'
        break
      case 6:
        unite = 'six'
        break
      case 7:
        unite = 'sept'
        break
      case 8:
        unite = 'huit'
        break
      case 9:
        unite = 'neuf'
        break
      }

      return unite
    },
    Dizaine (nombre) {
      let dizaine = ''
      switch (nombre) {
      case 10:
        dizaine = 'dix'
        break
      case 11:
        dizaine = 'onze'
        break
      case 12:
        dizaine = 'douze'
        break
      case 13:
        dizaine = 'treize'
        break
      case 14:
        dizaine = 'quatorze'
        break
      case 15:
        dizaine = 'quinze'
        break
      case 16:
        dizaine = 'seize'
        break
      case 17:
        dizaine = 'dix-sept'
        break
      case 18:
        dizaine = 'dix-huit'
        break
      case 19:
        dizaine = 'dix-neuf'
        break
      case 20:
        dizaine = 'vingt'
        break
      case 30:
        dizaine = 'trente'
        break
      case 40:
        dizaine = 'quarante'
        break
      case 50:
        dizaine = 'cinquante'
        break
      case 60:
        dizaine = 'soixante'
        break
      case 70:
        dizaine = 'soixante-dix'
        break
      case 80:
        dizaine = 'quatre-vingt'
        break
      case 90:
        dizaine = 'quatre-vingt-dix'
        break
      }
      return dizaine
    },
    NumberToLetter (nombre) {
      let quotient = 0, reste = 0
      let numberToLetter = ''
      //__________________________________

      if (nombre.toString().replace(/ /gi, '').length > 15) {
        return 'dépassement de capacité'
      }
      if (isNaN(nombre.toString().replace(/ /gi, ''))) {
        return 'Nombre non valide'
      }

      const nb = parseFloat(nombre.toString().replace(/ /gi, ''))
      if (Math.ceil(nb) !== nb) {
        return  'Nombre avec virgule non géré.'
      }

      const n = nb.toString().length
      switch (n) {
      case 1:
        numberToLetter = this.Unite(nb)
        break
      case 2:
        if (nb > 19) {
          quotient = Math.floor(nb / 10)
          reste = nb % 10
          if (nb < 71 || (nb > 79 && nb < 91)) {
            if (reste === 0) numberToLetter = this.Dizaine(quotient * 10)
            if (reste === 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-et-${  this.Unite(reste)}`
            if (reste > 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-${  this.Unite(reste)}`
          } else numberToLetter = `${this.Dizaine((quotient - 1) * 10)  }-${  this.Dizaine(10 + reste)}`
        } else numberToLetter = this.Dizaine(nb)
        break
      case 3:
        quotient = Math.floor(nb / 100)
        reste = nb % 100
        if (quotient === 1 && reste === 0) numberToLetter = 'cent'
        if (quotient === 1 && reste !== 0) numberToLetter = `cent ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.Unite(quotient)  } cents`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.Unite(quotient)  } cent ${  this.NumberToLetter(reste)}`
        break
      case 4 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 5 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 6 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 7:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 8:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 9:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 10:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 11:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 12:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 13:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 14:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 15:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      }//fin switch
      /*respect de l'accord de quatre-vingt*/
      if (numberToLetter.substr(numberToLetter.length - 'quatre-vingt'.length, 'quatre-vingt'.length) === 'quatre-vingt') numberToLetter = `${numberToLetter  }s`

      return numberToLetter.toUpperCase()
    },
    /* fin conversion nombre en lettre */
    getOfficineName (id) {
      const officine = this.$store.state.officine.officines.find((o) => o._id === id)
      return officine ? officine.NomOffic : ''
    },
    getMarqueName (id) {
      const singleMarque = this.$store.getters['marque/getMarqueById'](id)
      return singleMarque ? singleMarque.Libelle_marq : ''
    },
    getTypeName (id) {
      const singleType = this.$store.getters['type/getTypeById'](id)
      return singleType ? singleType.Type_Abreg : ''
    },
    designationFormatter () {
      return `${this.getMarqueName()} ${this.Modele}-${this.Couleur}`
    },
    OnSelectFournisseur (id) {
      const fournisseur = this.$store.state.fournisseur.fournisseurs.find((f) => f._id === id)
      this.fournisseurName = fournisseur.Nom
    },
    PrintCommandeFourn () {
      this.$refs.html2Pdf.generatePdf()
    },

    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    getCategorieArticle () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .then(() => {

        })
        .catch((error) => { console.log(error) })
    },
    getStyles () {
      this.$store.dispatch('style/getStyles')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getTypes () {
      this.$store.dispatch('type/getTypes')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    onProgress (e) {
      console.log(e)
      /* this.$vs.loading({
        type:'sound'
      }) */
    },
    hasGenerated (e) {
      console.log(e)
      this.$vs.loading.close()
    },

    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.idOfficine = this.activeUserInfos.IdOfficine
    },
    getTransfertArticleById () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('stock/getTransfertArticleById', this.$route.params.articleTransfereId)
        .then((resp) => {

          this.code_transfert = resp.data.code_transfert
          this.Officine_origine = resp.data.Officine_origine
          this.Officine_destination = resp.data.Officine_destination
          this.observetion = resp.data.observetion
          this.details = resp.data.details
          this.date_transfert = resp.data.createdAt

        })
        .catch(err => { console.log(err) })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    getLogo () {
      if (this.$store.state.entete_piedpage.logo) {
        this.$store.dispatch('entete_piedpage/getLogo')
          .then(() => {

          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    getPiedPage () {
      if (this.$store.state.entete_piedpage.piedpage) {
        this.$store.dispatch('entete_piedpage/getPiedPage')
          .then(() => {

          })
          .catch((err) => {
            console.error(err)
          })
      }
    }
  },
  created () {
    this.getLogo()
    this.getPiedPage()
    this.getTransfertArticleById()
    this.activeUserInfo()
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.categorie_article.categorie_articles.length > 0)) this.getCategorieArticle()
    if (!(this.$store.state.style.styles.length > 0)) this.getStyles()
    if (!(this.$store.state.marque.marques.length > 0)) this.getMarques()
    if (!(this.$store.state.type.types.length > 0)) this.getTypes()
  }
}
</script>

<style lang="scss">
.not-data-table{
  display: none;
}
/* .header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    > button {
      margin-left: 10px;
    }
  }
}

.footer-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > button {
    border: 0px solid rgba(0, 0, 0, 0) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
    border-radius: 0px !important;
  }
} */
</style>
